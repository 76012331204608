import { mapState, mapGetters, mapActions } from 'vuex'

// set your computed methods by mapping the state with data and setting our getters to call api;
export const paymentCategoryComputed = {
    ...mapState('paymentcategory', {
        paymentcategory:(state) => state.payment_categories,
    }),
    ...mapGetters('paymentcategory', ['getPayment'])
}

// export property actions
export const paymentCategoryMethods = mapActions('paymentcategory', ['allPaymentCategory','createPaymentCategory', 'updatePaymentCategory', 'deletePaymentCategory',
])
